#footer {
  background: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4vh;
}
  
.footer-content {
  text-align: center;
  padding: 20px;
}

.footer-content p:hover .my-name-footer {
  filter: brightness(2);
}
  
#footer p {
  color: #787878;
  margin: 0;
}

.footer-content a {
  color: #787878; 
  text-decoration: none; 
  cursor: pointer; 
  transition: color 0.3s ease-in-out; 
}
  
.social-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
  
.social-links a {
  margin: 0 10px; 
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.social-links img {
  width: 30px;
  height: auto;
  filter: brightness(0.5); 
  transition: filter 0.3s ease-in-out;
}

.social-links a:hover {
  cursor: pointer;
}

.social-links a:hover img {
  filter: brightness(1.2); 
}