nav {
    position: fixed;
    top: 0;
    left: 0;
    max-width:100vw;
    width: 100%;
    background-color: transparent;
    color: white;
    padding-top: 20px;
    text-align: center;
    height: 5vh;
    z-index: 1000; /* A high value to make sure it is above other elements */
}
  
nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
  
nav li {
    display: inline;
    margin-right: 10px;
    margin-left: 10px;
}
  
nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;
}
  
nav a:hover {
    color: #3498db;
}

.nav-light {
    background-color: rgba(0, 0, 0, 0.8); /* White with 80% opacity */
}

@media (max-width: 450px){
    nav a {
        font-size: 14px
    }
    nav li {
        margin-right: 7px;
        margin-left: 7px;
    }
}