body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: black;
}
  
section h1 {
    padding: 1vh;
    text-align: center;
    color: white;
}
  
#projects {
    background-color: black;
    min-height: 90vh;
}

@media screen and (max-width: 400px) {
    h1 {
        font-size:2em;
    }
}

