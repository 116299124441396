#projects{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#projects h1 {
    padding: 55px;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .projects-container .card {
    position: relative;
    width: 300px;
    height: 250px;
    transition: transform .4s, box-shadow .4s;
    margin: 10px;
  }
  
  .projects-container .card.hover {
    transform: translateY(-20px);
  }
  
  .projects-container .card .face {
    width: 100%;
    height: 100%;
    transition: .4s;
    backface-visibility: hidden;
  }
  
  .projects-container .card .face.face1 {
    position: absolute;
    background: rgba(75, 75, 75, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
  }
  
  .projects-container .card.hover .face.face1 {
    box-shadow:
      inset 0 0 50px whitesmoke,
      inset 20px 0 70px #ee5d2d, 
      inset -20px 0 70px #35b7ce, 
      inset 20px 0 200px #43ee2d6a, 
      inset -20px 0 200px #ec4b4b, 
      0 0 40px #eec32d, 
      -10px 0 70px #f81212, 
      10px 0 70px #709ab9; 
  }

  .content a {
    text-decoration:none;
  }
  
  .projects-container .card .face.face1 .content {
    text-align: center;
  }
  
  .projects-container .card.hover .face.face1 .content {
    opacity: 1;
  }
  
  .projects-container .card .face.face1 .content img {
    width: 55px;
    height: 55px;
    display: inline-block;
  }
  
  .projects-container .card .face.face1 .content h3 {
    font-size: 1em;
    color: white;
    text-align: center;
    margin-top: 10px;
    color: #fff;
  }
  
  .projects-container .card .face.face1 .content p {
    font-size: 0.8em;
    color: white;
    margin-top: 10px;
  }

  .card-description {
    user-select: none;
  }

  
  
  @media (max-width: 768px) {
    .projects-container {
      flex-direction: column;
      align-items: center;
    }
  
    .projects-container .card {
      margin: 20px 0;
    }
  }