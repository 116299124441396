#home {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
      120deg,
      rgba(7, 7, 9, 1) 16%,
      rgb(12, 10, 48) 96%
    );
}
  
.text-container {
  position: absolute;
  top: 23%;
  text-align: center;
  color: #ffffff;
  max-width: 90vw;
  width: 100%;
}

.text-container h1 {
    font-size: 4em;
}

.text-container p {
    font-size: 1.2em;
}

/* SCROLL BUTTON DOWN*/

#mouse-scroll {
  position: absolute;
  bottom: 30px; 
  transform: translateX(-50%);
  cursor: pointer;
}

#mouse-scroll span {
  display: block;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin: 0 0 3px 5.5px;
}

#mouse-scroll .mouse {
  height: 21px;
  width: 14px;
  border-radius: 10px;
  border: 2px solid #ffffff;
}
  
#mouse-scroll .arrow-1,
#mouse-scroll .arrow-2,
#mouse-scroll .arrow-3 {
  animation: mouse-scroll 1s infinite;
}
  
#mouse-scroll .arrow-1 {
  animation-delay: 0.1s;
  animation-direction: alternate;
}
#mouse-scroll .arrow-2 {
  animation-delay: 0.2s;
  animation-direction: alternate;
}
#mouse-scroll .arrow-3 {
  animation-delay: 0.3s;
  animation-direction: alternate;
}
#mouse-scroll .mouse-in {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: yellow;
  position: relative;
  animation: animated-mouse 1.2s ease infinite;
}
  
@keyframes animated-mouse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}
  
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
  
@media screen and (max-width: 420px) {
  .text-container h1{
    font-size:3em;
  }
}