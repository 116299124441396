#careerPath {
  background-color: black;
}

#careerPath h1 {
  padding: 55px;
}

.timelineItem {
  margin: 0 0 50px;
  position: relative;
}

.timelineItem:nth-child(2n+1) {
  text-align: right;
}
  
.timeline {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.timeline_line {
  width: 2px;
  background-color: #393935;
  position: absolute;
  top: 2px;
  left: 50%;
  bottom: 0;
  overflow: hidden;
  transform: translateX(-50%);
}

.timeline_line_progress {
  width: 100%;
  height: 20%;
  background-color: #FF0;
}

.timelineItemContainer {
  padding: 0 0 20px 50%;
}

.timelineItem:nth-child(2n+1) .timelineItemContainer {
  padding: 0 50% 20px 0;
}

.timelineYear_circle {
  display: inline-block;
  margin: 0 14px 0 -28px;
}

.timelineItem:nth-child(2n+1) .timelineYear_circle {
  margin: 0 -28px 0 14px;
}

.timelineYear {
  height: 50px;
  line-height: 50px;
  width: 50px;
  border: 3px solid #FF0;
  background-color: #1d1d1b;
  text-align: center;
  font-size: 20px;
  color: #FFF;
  border-radius: 50%;
}

.js-ag-active .timelineYear {
  color: #1d1d1b;
  background-color: #FF0;
}
  
.experience {
  display: inline-block;
  width: 45%;
  margin: -77px 13px 0 12px;
  background-color: #282828;
  opacity: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .5);
  transition: transform .5s, opacity .5s;
  position: relative;
}
   
.js-ag-active.timelineItem:nth-child(2n) .experience {
  opacity: 1;
  transform: translateX(0);
}

/*  For experiences in even positions (left) */
.js-ag-active.timelineItem:nth-child(2n+1) .experience {
    opacity: 1;
    transform: translateX(0);
}

.timelineArrow {
  height: 18px;
  width: 18px;
  margin-top: 20px;
  background-color: #282828;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg);
}

.timelineItem:nth-child(2n) .timelineArrow {
  margin-left: calc(-18px / 2);
  margin-right: calc(-18px / 2);
}

.timelineItem:nth-child(2n+1) .timelineArrow {
  margin-left: -10px;
  right: auto;
  left: 0;
}

.experienceContent {
  padding: 20px;
  background-color: #f4f4f4; 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.experienceDescription {
  font-size: 16px;
  line-height: 1.6;
  color: #444; 
  text-align: left;
  margin-bottom: 15px;
} 

.experienceTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; 
  text-align: left;
}

.experienceLocationTime {
  font-size: 16px;
  color: #666; 
  margin-bottom: 10px;
  text-align: left;
}

.experienceTitle,
.experienceLocation,
.experienceContent {
  height: 15%;
  overflow: hidden;
}

.experienceTitle h2,
.experienceLocation h3,
.experienceDescription {
  margin: 0;
  padding: 0;
}

.experienceDescription li {
  margin-top: 15px;
}

.experienceTitle,
.experienceLocation {
  margin-bottom: 10px;
}

ul {
  padding-left: 15px;
}

@media only screen and (max-width: 979px) {
  .timeline_line {
    left: 30px;
  }

  .timelineItem:nth-child(2n+1) {
    text-align: left;
  }

  .timelineItemContainer,
  .timelineItem:nth-child(2n+1) .timelineItemContainer {
    padding: 0 0 20px;
  }

  .timelineYear_circle,
  .timelineItem:nth-child(2n+1) .timelineYear_circle {
    margin: 0 0 0 8px;
  }

  .timelineYear {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .experience {
    width: auto;
    margin: -65px 13px 0 75px;
  }

  .timelineItem:nth-child(2n) .timelineArrow {
    right: auto;
    left: 0;
  }

  .timelineArrow {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    width: 96%;
  }

  .timeline-card_img {
    height: auto;
    width: auto;
  }

  .timelineYear {
    font-size: 18px;
  }
}

@media only screen and (max-width: 639px) {
  .experienceContent {
    padding: 10px;
  }

  .experienceTitle {
    font-size: 20px;
  }

  .experienceDescription {
    font-size: 16px;
  }

  .timelineYear {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .container {
    width: 750px;
  }

  .timelineYear {
    font-size: 18px;
  }
}