@import "normalize.css";

#about {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
}

:root {
	--bg: hsl(246 44% 7%);
	--border: hsl(281, 88%, 35%);
	--card: hsl(237 36% 10%);
	--color: hsl(240, 85%, 43%);
	--border-width: 2px;
	--border-radius: 12px;
	--gradient: conic-gradient(from 180deg at 50% 70%,hsla(0,0%,98%,1) 0deg,#eec32d 72.0000010728836deg,#ec4b4b 144.0000021457672deg,#709ab9 216.00000858306885deg,#4dffbf 288.0000042915344deg,hsla(0,0%,98%,1) 1turn);
}

.glows {
	pointer-events: none;
	position: absolute;
	inset: 0;
	filter: blur(calc(var(--blur) * 1px));
}

 


.container {
  --spread: 60;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: calc(var(--gap) * 1px);
  margin: 0 auto;
  justify-content: center;
  place-items: center;
  position: relative;
  border: 3px;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  --blur: 10;
  --spread: 80;
  --direction: row;
  background-color: black;
}

article {
  --active: 0.15;
  --start: 0;
  width: 90vw;
  max-width: 90vw;
  margin: 0 auto;
  background: #101123;
  border-radius: var(--border-radius);
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}

@media (hover: hover) { /*For non-touchscreen devices*/
  
  .glows::after,
  .glows::before {
    --alpha: 0;
    content: "";
    background: var(--gradient);
    background-attachment: fixed;
    position: absolute;
    inset: -5px;
    border: 10px solid transparent;
    border-radius: var(--border-radius);
    mask:
      linear-gradient(#0000, #0000),
      conic-gradient(from calc((var(--start) - (var(--spread) * 0.5)) * 1deg), #000 0deg, #fff, #0000 calc(var(--spread) * 1deg));
    mask-composite: intersect;
    mask-clip: padding-box, border-box;
    opacity: var(--active);
    transition: opacity 1s;
  }

}

 
article h2 {
	margin: 0;
	padding: 1rem 0;
	font-weight: 100;
	font-size: 1.5rem;
}

article h1 {
  color: white;
}

#contentAboutMe {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  display: flex;
}

#leftColumn {
  flex: 2.5;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

#rightColumn {
  flex: 7.5;
  min-width: 0;
  word-wrap: break-word;
}

#leftColumn p {
  text-align: left;
  overflow: hidden;
  color:#d5d4d4;
}

#rightColumn p {
  text-align: justify;
  overflow: hidden;
  color: #d5d4d4;
}

#leftTopRow {
  /* height: 150px; */
}


#leftBottomRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* max-height: calc(100% - 35%); */
  overflow: hidden;
}

#leftBottomRow h2 {
  text-align: left;
  /* margin-bottom: 15px; */
  color: white;
  font-weight:bold;
}

#leftBottomRow p {
  display: flex;
  text-align: left;
  margin-bottom: 15px;
  color: white;
}

.contact-details {
  margin-bottom: 15px;
}

.contact-details p {
  max-width: 100%;
  word-wrap: break-word;
  overflow: hidden;
}

button.download-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  /* margin-right: 70px; */
}

button.download-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: white; 
}


.profileImageContainer {
  display: flex;
  overflow: hidden;
  /* padding-top: 14%; */
  padding-left: 15%;
}

.profileImage {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.flag {
  display: inline-block;
  border: 1px solid #7d7d7d;
  border-radius: 30%;
  padding: 2px;
}

@media screen and (max-width: 1200px) {
    .contact-details p {
      font-size: 15px;
    }
}


@media screen and (max-width: 979px) {
  #contentAboutMe {
    flex-direction: column;
    align-items: center;
  }

  #leftColumn {
    flex: 1;
    width: 100%;
    max-width: 100%;
  }

  #rightColumn {
    flex: 1;
    width: 100%;
    max-width: 100%;
  }

  .profileImageContainer {
    justify-content: center;
    margin-bottom: 20px;
  }

  #leftBottomRow {
    align-items: center;
    justify-content: center;
  }

  .profileImageContainer {
    padding-top: 0;
    padding-left: 0;
  }

  #about {
    flex-direction: column;
    align-items: center;
  }

  article {
    width: 100%;
    max-width: 100%;
    height: auto; 
  }
  
  button.download-button {
    margin-right: 0px;
  }

  #rightColumn p {
    text-align: left;
  }

  #leftBottomRow p {
    display: block;
    text-align: center;
  }


}

