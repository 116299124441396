#abilities{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#abilities h1 {
    padding: 55px;
}
  
.bar-graph-vertical {
  display: flex;
  justify-content: center;
  margin-top: 1%; 
  max-width: 90%; 
  height: 50vh; 
}

.bar-graph-vertical .bar-container {
  height: 90%;
  margin-right: 8px;
  position: relative;
  text-align: center;
  width: 40px;
}

.bar-graph-vertical .bar {
  border: 1px solid white; 
  border-radius: 3px;
  bottom: 40px;
  position: absolute;
  width: 40px;
  background-color: rgba(100, 178, 209, 0.8);
}

.bar-graph .bar::after {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
  color: #fff;
  content: attr(data-percentage);
  font-weight: 700;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: left;
  top: 40px;
  transform-origin: 50% 50%;
  transform: translateX(-50%) rotate(-90deg);
}

.bar-graph-vertical .ability {
  bottom: -9%; 
  left: 50%;
  position: absolute;
  transform: translateX(-50%) rotate(-90deg);
  white-space: nowrap; 
  color: white;
}

.bar .bar-value {
  color: white;
}

.bar-graph .bar-one .bar {
  background-color: #6bc6ea;
  animation: show-bar1 1.2s 0.1s forwards;
}

.bar-graph .bar-two .bar {
  background-color: #5292ac;
  animation: show-bar2 1.2s 0.2s forwards;
}

.bar-graph .bar-three .bar {
  background-color: #6bc6ea;
  animation: show-bar3 1.2s 0.3s forwards;
}

.bar-graph .bar-four .bar {
  background-color: #5292ac;
  animation: show-bar4 1.2s 0.4s forwards;
}
.bar-graph .bar-five .bar {
  background-color: #2e515f;
  animation: show-bar5 1.2s 0.5s forwards;
}

.bar-graph .bar-six .bar {
  background-color: #5292ac;
  animation: show-bar6 1.2s 0.6s forwards;
}

.bar-graph .bar-seven .bar {
  background-color: #6bc6ea;
  animation: show-bar7 1.2s 0.6s forwards;
}

.bar-graph .bar-eight .bar {
  background-color: #5292ac;
  animation: show-bar8 1.2s 0.6s forwards;
}

.bar-graph .bar-nine .bar {
  background-color: #2e515f;
  animation: show-bar9 1.2s 0.6s forwards;
}

.bar-graph .bar-ten .bar {
  background-color: #5292ac;
  animation: show-bar10 1.2s 0.6s forwards;
}
  
/* Bar Graph Vertical Animations */
@keyframes show-bar1 {
  0% {
    height: 10%;
  }
  100% {
    height: 100%;
  }
}

@keyframes show-bar2 {
  0% {
    height: 10%;
  }
  100% {
    height: 80%;
  }
}

@keyframes show-bar3 {
  0% {
    height: 10%;
  }
  100% {
    height: 100%;
  }
}

@keyframes show-bar4 {
  0% {
    height: 10%;
  }
  100% {
    height: 80%;
  }
}

@keyframes show-bar5 {
  0% {
    height: 10%;
  }
  100% {
    height: 60%;
  }
}

@keyframes show-bar6 {
  0% {
    height: 10%;
  }
  100% {
    height: 80%;
  }
}

@keyframes show-bar7 {
  0% {
    height: 10%;
  }
  100% {
    height: 100%;
  }
}

@keyframes show-bar8 {
  0% {
    height: 10%;
  }
  100% {
    height: 80%;
  }
}

@keyframes show-bar9 {
  0% {
    height: 10%;
  }
  100% {
    height: 60%;
  }
}

@keyframes show-bar10 {
  0% {
    height: 10%;
  }
  100% {
    height: 80%;
  }
}

@keyframes fade-in-text {
  0% {
    opacity: 10%;
  }
  100% {
    opacity: 1;
  }
}

.explanation {
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-top: 5vh;
  border:1px solid;
  border-color: #898989;
}

.explanation-container {
  width:55vw;
  margin: 0 auto;
}

@media screen and (max-width: 979px) {

  .bar-graph-vertical .bar{
    width: 30px;
  }

  .bar-graph-vertical .bar-container{
    margin-right: 0px;
  }
}

@media screen and (max-width: 420px) {

  .bar-graph-vertical .bar{
    width: 20px;
  }

  .bar-graph-vertical .bar-container{
    margin-right: 0px;
  }

  .bar-graph-vertical .bar-container{
    width:30px;
  }
}